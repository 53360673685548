import React, {useState} from "react";
import MensajesIngles from '../lang/en-US.json';
import MensajesEspanol from '../lang/en-ES.json';
import { IntlProvider } from "react-intl";

const langContext = React.createContext();

const LangProvider = ({children}) =>{
    let localPorDefecto;
    let mensajesPorDefecto;
    const lang = localStorage.getItem('lang');

    if(lang){
        localPorDefecto = lang

        if(lang === 'es-MX'){
            mensajesPorDefecto = MensajesEspanol;
        } else if (lang === 'en-US'){
            mensajesPorDefecto = MensajesIngles
        } else {
            localPorDefecto = 'es-MX'
            mensajesPorDefecto = MensajesEspanol
        }
    }

    const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
    const [locales, establecerLocales] = useState(localPorDefecto);

    const establecerLenguaje = (lenguaje) => {
        switch (lenguaje){
            case 'es-MX':
                establecerMensajes(MensajesEspanol);
                establecerLocales('es-MX');
                localStorage.setItem('lang', 'es-MX');
                break;
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocales('en-US');
                localStorage.setItem('lang', 'en-US');
                break;
            default:
                establecerMensajes(MensajesEspanol);
                establecerLocales('es-MX');
                localStorage.setItem('lang', 'es-MX');
        }
    }

    return (
        <langContext.Provider value={{establecerLenguaje: establecerLenguaje}}>
            <IntlProvider locale={locales} messages={mensajes}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}

export {LangProvider, langContext};