import React from 'react';
import '../index.css';
import {FormattedMessage} from 'react-intl';

class ProcesoTrabajo extends React.Component {

    render() {
        return (
            <>

                <div className="container mx-auto xl:px-24 2xl:px-48">
                    <h1 className="text-center text-xl md:text-4xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book" data-wow-delay="1s"> <span className="font-bold Ma-demi">
                    <FormattedMessage
                        id="process.titleOne" 
                        defaultMessage="NUESTRO" 
                    /> 
                    </span>
                    <FormattedMessage
                        id="process.titleTwo" 
                        defaultMessage=" PROCESO" 
                    />
                    <br></br>
                    <FormattedMessage
                        id="process.titleThree" 
                        defaultMessage="DE TRABAJO" 
                    />
                    </h1>

                    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 md:mt-10 mt-10 mb-10">
                        <div className="w-70 h-70 xl:place-self-start place-self-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div className="Rombo Ma-book R01">
                                <div className="text-content">
                                    <p className="text-center text-md pt-44 px-16">
                                    <FormattedMessage
                                        id="proccess.one" 
                                        defaultMessage="Definición del proyecto y análisis de los requisitos." 
                                    />
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div className="w-70 h-70 xl:place-self-start place-self-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div className="Rombo Ma-book R02">
                                <div className="text-content">
                                    <p className="text-center text-md pt-44 px-16">
                                    <FormattedMessage
                                        id="proccess.two" 
                                        defaultMessage="Se programa el código en relación de la demanda del soware." 
                                    />
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div className="w-70 h-70 xl:place-self-start place-self-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div className="Rombo Ma-book R03">
                                <div className="text-content">
                                    <p className="text-center text-md pt-44 px-16">
                                    <FormattedMessage
                                        id="proccess.three" 
                                        defaultMessage="Se detectan posibles errores de software." 
                                    />
                                    </p>
                                </div>

                            </div>

                        </div>

                        <div className="w-70 h-70 xl:place-self-end place-self-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div className="Rombo Ma-book R04">
                                <div className="text-content">
                                    <p className="text-center text-md pt-44 px-16">
                                    <FormattedMessage
                                        id="proccess.for" 
                                        defaultMessage="Facilita la mejora y el mantenimiento a lo largo del proyecto." 
                                    />
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div className="w-70 h-70 xl:place-self-end place-self-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div className="Rombo Ma-book R05">
                                <div className="text-content">
                                    <p className="text-center text-md pt-44 px-16">
                                    <FormattedMessage
                                        id="proccess.five" 
                                        defaultMessage="Se distribuye en el entorno de producción." 
                                    />
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div className="w-70 h-70 xl:place-self-end place-self-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            <div className="Rombo Ma-book R06">
                                <div className="text-content">
                                    <p className="text-center text-md pt-44 px-16">
                                    <FormattedMessage
                                        id="proccess.six" 
                                        defaultMessage="Es la mejora del software despúes de su despliegue." 
                                    />
                                    </p>
                                </div>

                            </div>




                        </div>

                    </div>


                </div>



            </>
        )
    }
}

export default ProcesoTrabajo;

