import React from 'react';

import MenuPrincipal from '../components/MenuPrincipal';
import Footer from '../components/footer';
import imgBanner from '../images/portada-inicio.webp';
import Clientes from '../components/Cliente';
import ProcesoTrabajo from '../components/ProcesoTrabajo';
import DescripcionNosotros from '../components/DescripcionNosotros';
import Certificaciones from '../components/Certificaciones';

import servicioCinco from '../images/servicio-5.webp';
import servicioSeis from '../images/servicio-6.webp';
import servicioSiete from '../images/servicio-7.webp';
import servicioOcho from '../images/servicio-8.webp';
import {FormattedMessage} from 'react-intl';



class Inicio extends React.Component {
    
    //static contextType = langContext

    render() {
        //const idioma = this.context;
        return (
            <>
                    <div className="img-banner-principal" style={{ backgroundImage: `url(${imgBanner})` }}>
                        <MenuPrincipal></MenuPrincipal>
                        <div className="container mx-auto text-white xs-pl-1">
                            <h1 className='p-top-100 Ma-light xs-text-size sm:text-xl md:text-2xl lg:text-2xl lg:text-4xl pt-96'>
                                <FormattedMessage
                                    id="app.welcomeOne" 
                                    defaultMessage="Somos una empresa dedicada al" 
                                /> <br />
                                <span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="app.welcomeTwo" 
                                    defaultMessage="desarrollo de software" 
                                />
                                </span>
                                <FormattedMessage
                                    id="app.welcomeThree" 
                                    defaultMessage=" y servicios" 
                                />
                                <br />
                                <FormattedMessage
                                    id="app.welcomeFor" 
                                    defaultMessage=" de" 
                                />
                                 <span className='font-bold Ma-med'>
                                 <FormattedMessage
                                    id="app.welcomeFive" 
                                    defaultMessage=" tecnologías de la información" 
                                />
                                 </span>
                            </h1>
                            <div className="text-left pt-12 Ma-light">
                                <a href="/servicios" className="mx-auto bg-md text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide">
                                <FormattedMessage
                                    id="app.buttonOne" 
                                    defaultMessage="NUESTROS SERVICIOS" 
                                />
                                </a>
                            </div>
                        </div>
                    </div>

                <div className="py-32">
                    <div className='mx-auto bg-white w-11/12'>
                        <Clientes></Clientes>
                    </div>
                </div>

            
                <div className="img-inicio-descripcion text-center flex items-center justify-center px-44 py-32 md:py-28 md:px-14 lg:px-44  xs-py-20 xs-px-4">
                    <h1 className='xs-text-size-2 ls-text-5xl text-white text-center sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl Ma-light pl-40 pr-40 xs-p-0'>
                        <FormattedMessage
                            id="app.description" 
                            defaultMessage="Ofrecemos soluciones de software accesibles y completas de acuerdo a la empresa lo requiera, brindando soluciones realistas, aplicables a su contexto actual con una visión de mediano y largo plazo." 
                        />
                    </h1>
                </div>

                <div className='container mx-auto px-44 py-32 md:py-28 md:px-14 lg:px-44  xs-py-20 xs-px-4'>
                    <h1 className="text-center text-xl md:text-4xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book" data-wow-delay="1s"> <span className="font-bold Ma-demi">
                    <FormattedMessage
                        id="services.titleOne" 
                        defaultMessage="NUESTROS" 
                    />
                    </span>
                    <FormattedMessage
                        id="services.titleTwo" 
                        defaultMessage=" SERVICIOS" 
                     />
                    </h1>
                    <div className='py-24'>
                        <div className="grid gap-x-24 gap-y-14 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                            <div>
                                <img className='m-auto h-80 xs-h-auto' src={servicioCinco} alt="servicio uno"></img>
                                <h1 className='text-gray-600 Ma-light text-center text-3xl xs-text-size-3 pt-8'>
                                <FormattedMessage
                                    id="service.factoryOne" 
                                    defaultMessage="FÁBRICA DE" 
                                />   
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.factoryTwo" 
                                    defaultMessage="SOFTWARE" 
                                />     
                                </span></h1>
                            </div>
                            <div className='xs-mt-4 sm:mt-14 md:mt-14 lg:mt-14 xl:mt-0 2xl:mt-0'>
                                <img className='m-auto h-80 xs-h-auto' src={servicioSeis} alt="servicio dos"></img>
                                <h1 className='text-gray-600 Ma-light text-center text-3xl pt-8 xs-text-size-3'>
                                <FormattedMessage
                                    id="service.modelingOne" 
                                    defaultMessage="MODELADO DE" 
                                />   
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.modelingTwo" 
                                    defaultMessage="PROCESO DE NEGOCIO" 
                                />
                                </span></h1>
                            </div>
                            <div>
                                <img className='m-auto h-80 xs-h-auto' src={servicioSiete} alt="servicio tres"></img>
                                <h1 className='text-gray-600 Ma-light text-center text-3xl pt-8 xs-text-size-3'>
                                <FormattedMessage
                                    id="service.innovationOne" 
                                    defaultMessage="INNOVACIÓN Y" 
                                />   
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.innovationTwo" 
                                    defaultMessage="DESARROLLO DE SOFTWARE" 
                                />
                                </span></h1>
                            </div>
                            <div className='xs-mt-4 sm:mt-14 md:mt-14 lg:mt-14 xl:mt-0 2xl:mt-0'>
                                <img className='m-auto h-80 xs-h-auto' src={servicioOcho} alt="servicio cuatro"></img>
                                <h1 className='text-gray-600 Ma-light text-center text-3xl pt-8 xs-text-size-3'>
                                <FormattedMessage
                                    id="service.cloudOne" 
                                    defaultMessage="SERVICIO DE" 
                                />   
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.cloudTwo" 
                                    defaultMessage="COMPUTACIÓN EN LA NUBE" 
                                />
                                </span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="grid place-content-center pt-5 Ma-light">
                        <a href="/contacto" className="mx-auto bg-md text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                        <FormattedMessage
                            id="app.buttonTwo" 
                            defaultMessage="CONTACTO" 
                        />
                        </a>
                    </div>
                </div>

                <div className="mx-auto bg-gray-200 py-32">
                    <ProcesoTrabajo></ProcesoTrabajo>
                    <div className="grid place-content-center pt-5 Ma-light">
                        <a href="/servicios" className="mx-auto bg-md text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                        <FormattedMessage
                            id="app.buttonThree" 
                            defaultMessage="NUESTROS SERVICIOS" 
                        />
                        </a>
                    </div>
                </div>

                <div className='container mx-auto px-44 py-32 xs-py-20 md:py-28 md:px-14 lg:px-44 xs-px-4'>
                    <h1 className="text-center text-xl md:text-4xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book mb-20" data-wow-delay="1s"> <span className="font-bold Ma-demi">
                    <FormattedMessage
                        id="about.titleOne" 
                        defaultMessage="¿QUIÉNES" 
                    />
                    </span>
                    <FormattedMessage
                        id="about.titleTwo" 
                        defaultMessage=" SOMOS?" 
                    />
                    </h1>
                    <DescripcionNosotros></DescripcionNosotros>
                </div>

                <div className="container mx-auto px-44 py-20 pb-24 xs-py-20 xs-px-4">
                    <Certificaciones></Certificaciones>
                    <div className="grid place-content-center mt-20 Ma-light">
                        <a type="button" href="/contacto" className="mx-auto bg-md text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                        <FormattedMessage
                            id="app.buttonTwo" 
                            defaultMessage="CONTACTO" 
                        />
                        </a>
                    </div>
                </div>

                <Footer></Footer>
            </>
        )
    }
}


export default Inicio;