import React from 'react';
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import Navbar from '../components/Navbar';
import Clientes from '../components/Cliente';
import "animate.css";
import WOW from 'wowjs';
import Footer from '../components/footer';
import '../index.css';
import '../slider.css';
import SwiperCore, {
    EffectCoverflow,
    Pagination,
    Navigation
} from "swiper/core";
import imgServicios from '../images/servicios.webp';
import {FormattedMessage} from 'react-intl';

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);


class Servicios extends React.Component {
    componentDidMount() {
        new WOW.WOW({
          live: false
      }).init();
      }
    render() {
        return (

            <>
                <Navbar titlelight="services.titleOne" title="services.titleTwo" titlelightDefaul="NUESTROS" titleDefaul="SERVICIOS" image={imgServicios} />
                <div className="bg-gray-100">
                    <div className="container mx-auto px-44 py-32 md:py-28 md:px-14 lg:px-44  xs-py-20 xs-px-4">
                        <p className='text-gray-500 text-2xl font-medium Ma-book tracking-normal leading-normal txt-silver text-center margin-parrafo'>
                            <FormattedMessage
                                id="services.descriptionOne" 
                                defaultMessage="Ofrecemos" 
                            />
                            <span className='font-bold Ma-med'>
                            <FormattedMessage
                                id="services.descriptionTwo" 
                                defaultMessage=" soluciones de software accesibles y completas" 
                            />
                            </span>
                            <FormattedMessage
                                id="services.descriptionThree" 
                                defaultMessage=" de acuerdo a lo que la empresa requiera, brindando soluciones realistas, aplicables a su contexto actual, pero con visión de mediano y largo plazo." 
                            />
                        </p>
                        <div className="grid place-content-center mt-16 Ma-light">
                            <a href="/contacto" className="mx-auto bg-md text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                            <FormattedMessage
                                id="app.buttonTwo" 
                                defaultMessage="CONTACTO" 
                            />
                            </a>
                        </div>
                            
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-12 gap-y-12 my-28">
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 img-servicios img-servicio-uno text-center flex items-center justify-center">
                                <h1 className='text-white Ma-light text-3xl'>
                                <FormattedMessage
                                    id="service.factoryOne" 
                                    defaultMessage="FÁBRICA DE" 
                                />
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.factoryTwo" 
                                    defaultMessage="SOFTWARE" 
                                />
                                </span></h1>
                            </div>
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 m-auto">
                                <p className="text-gray-500 text-xl text-justify font-medium Ma-book tracking-normal leading-normal txt-silver">
                                <FormattedMessage
                                    id="service.factoryDescrip" 
                                    defaultMessage="Contamos con un sólido equipo humano en Ingeniería de Software que sigue un proceso sistemático, disciplinado y cuantificable para el desarrollo, operación y mantenimiento de software.
                                     Gestión de proyectos bajo el enfoque PMI © PMBOK e implementación de software bajo el enfoque ISO/IEC 29110." 
                                />
                                </p>
                            </div>
                        </div>

                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-12 my-28">
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 m-auto xs-order-2">
                                <p className="text-gray-500 text-xl text-justify font-medium Ma-book tracking-normal leading-normal txt-silver">
                                <FormattedMessage
                                    id="service.modelingDescrip" 
                                    defaultMessage="La Gestión de Procesos de Negocio es una metodología corporativa, cuyo objetivo es mejorar el desempeño y la optimización de los procesos de negocio 
                                    de una organización, a través de la gestión de los procesos que se deben diseñar, modelar, organizar, documentar y optimizar de forma continua." 
                                />
                                </p>
                            </div>
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 img-servicios img-servicio-dos text-center flex items-center justify-center">
                                <h1 className='text-white Ma-light text-3xl'>
                                <FormattedMessage
                                    id="service.oneModeling" 
                                    defaultMessage="MODELADO DE" 
                                />
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.twoModeling" 
                                    defaultMessage="PROCESO DE" 
                                />    
                                <br/>
                                <FormattedMessage
                                    id="service.threeModeling" 
                                    defaultMessage="NEGOCIO" 
                                />
                                </span></h1>
                            </div>
                        </div>

                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-12 my-28">
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 img-servicios img-servicio-tres text-center flex items-center justify-center">
                                <h1 className='text-white Ma-light text-3xl'>
                                <FormattedMessage
                                    id="service.oneInnovation" 
                                    defaultMessage="INNOVACIÓN Y" 
                                />    
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.twoInnovation" 
                                    defaultMessage="DESARROLLO DE" 
                                />
                                <br/>
                                <FormattedMessage
                                    id="service.threeInnovation" 
                                    defaultMessage="SOFTWARE" 
                                />
                                </span></h1>
                            </div>
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 m-auto">
                                <p className="text-gray-500 text-xl text-justify font-medium Ma-book tracking-normal leading-normal txt-silver">
                                <FormattedMessage
                                    id="service.innovationDescrip" 
                                    defaultMessage="Desarrollamos productos innovadores, robustos, seguros y escalables,utilizamos las mejores tecnologías de acuerdo a las necesidades 
                                    y preferencias de nuestros clientes. Nos basamos en las mejores prácticas y nos orientamos a la búsqueda de la mejor calidad." 
                                />
                                </p>
                            </div>
                        </div>

                        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-12 my-28">
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 m-auto xs-order-2">
                                <p className="text-gray-500 text-xl text-justify font-medium Ma-book tracking-normal leading-normal txt-silver">
                                <FormattedMessage
                                    id="service.cloudDescrip" 
                                    defaultMessage="Ofrecemos servicios de computación en la nube como un servicio a través de Internet, con entrega según demanda y pago según el uso.
                                    Estos servicios abarcan desde ejecución de aplicaciones software, bases de datos, almacenamiento y escritorios virtuales. Contamos con proveedores de servicios con altos estándares de seguridad para su negocio." 
                                />
                                </p>
                            </div>
                            <div className="sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 img-servicios img-servicio-cuatro text-center flex items-center justify-center">
                                <h1 className='text-white Ma-light text-3xl'>
                                <FormattedMessage
                                    id="service.oneCloud" 
                                    defaultMessage="SERVICIO DE" 
                                />
                                <br/><span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="service.twoCloud" 
                                    defaultMessage="COMPUTACIÓN" 
                                />
                                <br/>
                                <FormattedMessage
                                    id="service.threeCloud" 
                                    defaultMessage="EN LA NUBE" 
                                />
                                </span></h1>
                            </div>
                        </div>

                        <div className="grid place-content-center Ma-light">
                            <a href="/contacto" className="mx-auto bg-md text-white py-3 px-10 mb-14 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                            <FormattedMessage
                                id="app.buttonTwo" 
                                defaultMessage="CONTACTO" 
                            />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="py-32">
                    <div className='mx-auto bg-white w-11/12'>
                        <Clientes></Clientes>
                    </div>
                </div>
              
                <Footer></Footer>
            </>
        )
    }
}

export default Servicios;
