import React from 'react';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logoGris from '../images/logo-b.webp';
import { FaCheck } from "react-icons/fa";

import face from '../images/Iconos/Redes/iiconos-11.png';
import insta from '../images/Iconos/Redes/iiconos-12.png';
import link from '../images/Iconos/Redes/iiconos-13.png';
import wsp from '../images/Iconos/WhatsApp inicio.png';
import {FormattedMessage} from 'react-intl';
import '../index.css';

export default function Example() {

    const [open, setOpen] = useState(false)
    const [terminos, setTerminos] = useState(false)

    const cancelButtonRef = useRef(null)

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto z-index-Mod" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:align-middle md:w-3/5">
                                <div className=" bg-md px-5 pb-5 pt-7">
                                    <div className="flex justify-between">

                                        <div className="mt-3 col-start-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-xl leading-6 font-bold text-white Ma-demi">
                                                <FormattedMessage
                                                    id="footer.policy" 
                                                    defaultMessage="Políticas de privacidad" 
                                                />
                                            </Dialog.Title>

                                        </div>
                                        <div className="justify-end flex items-center h-12 w-12 rounded-full shadow-xl bg-gray-300 sm:mx-0 sm:h-10 sm:w-10">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center border-gray-30 px-3 py-2 text-base font-extrabold text-black transform motion-safe:hover:scale-110 "
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-7 py-7 sm:flex sm:flex-row-reverse Ma-light">

                                    <p className="fs-9 leading-normal tracking-wide txt-silver font-semibold text-justify">
                                        <FormattedMessage
                                            id="privacy.policyOne" 
                                            defaultMessage="Por medio de nuestra Política de Privacidad le ponemos al tanto de las distintas formas en que MICRODATA SRL (en adelante, “nosotros”) y nuestros sitios afiliados (en adelante, “sitio web”) maneja la información que usted nos provee mientras navega en ellos." 
                                        />
                                         <br></br>
                                        <FormattedMessage
                                            id="privacy.policyTwo" 
                                            defaultMessage="La utilización de éstos implica su aceptación plena y sin reservas a todas y cada una de las disposiciones incluidas en este Aviso Legal, por lo que si usted no está de acuerdo con cualquiera de las condiciones expuestas, no deberá hacer uso ni acceder a este sitio." 
                                        />
                                        <FormattedMessage
                                            id="privacy.policyThree" 
                                            defaultMessage=" Nos reservamos el derecho a modificar esta Declaración de Privacidad en cualquier momento. Su uso continuo de cualquier porción de este sitio tras la notificación o anuncio de tales modificaciones constituirá su aceptación de tales cambios." 
                                        />
                                        <br></br>
                                        <div className="my-2">
                                            <p className="font-bold pb-2">
                                                <FormattedMessage
                                                    id="privacy.policyFor" 
                                                    defaultMessage="Política de protección de datos personales:" 
                                                />
                                            </p>
                                        <FormattedMessage
                                            id="privacy.policyFive" 
                                            defaultMessage="Para utilizar algunos de los servicios o acceder a determinados contenidos, deberá proporcionar previamente ciertos datos de carácter personal que no son obligatorios a menos que se indique lo contrario y que son requeridos para poder prestarle el servicio o acceder al contenido." 
                                        />
                                        <br></br>
                                        <FormattedMessage
                                            id="privacy.policySix" 
                                            defaultMessage="MICRODATA SRL podrá utilizar esta información para propósitos estadísticos o de estudio del comportamiento de los visitantes en nuestros sitios. Cómo principio general, no compartimos ni revelamos la información obtenida, excepto cuando haya sido autorizada por usted, o en los siguientes casos:" 
                                        />
                                        <ol className="list-decimal px-5 py-3">
                                                <li>
                                                    <FormattedMessage
                                                        id="privacy.policySeven" 
                                                        defaultMessage="Cuando le sea requerido por una autoridad competente y previo el cumplimiento del trámite legal correspondiente y" 
                                                    />
                                                </li>
                                                <li>
                                                    <FormattedMessage
                                                        id="privacy.policyEight" 
                                                        defaultMessage="Cuando a juicio de este sitio sea necesario para hacer cumplir las condiciones de uso y demás términos de esta página, o para salvaguardar la integridad de los demás usuarios o del sitio." 
                                                    />
                                                    <FormattedMessage
                                                        id="privacy.policyNine" 
                                                        defaultMessage=" Este sitio web puede contener enlaces hacia otros sitios web ajenos de los cuales no nos responsabilizamos por las prácticas que hagan en materia de privacidad y protección de datos personales, por lo que le recomendamos leer las políticas de privacidad de dichos sitios." 
                                                    />
                                                </li>
                                            </ol>
                                        <FormattedMessage
                                            id="privacy.policyTen" 
                                            defaultMessage="Deberá estar consciente de que si usted voluntariamente revela información personal en línea en un área pública, esa información puede ser recogida y usada por otros. Nosotros no controlamos las acciones de nuestros visitantes y usuarios." 
                                        />
                                        <FormattedMessage
                                            id="privacy.policyEleven" 
                                            defaultMessage=" Conducta Responsable: Toda la información que facilite deberá ser veraz. A estos efectos, usted garantiza la autenticidad de todos aquellos datos que comunique como consecuencia de la cumplimentación de los formularios necesarios para la suscripción de los Servicios, acceso a contenidos o" 
                                        />
                                        <FormattedMessage
                                            id="privacy.policyTwelve" 
                                            defaultMessage=" áreas restringidas del sitio. En todo caso usted será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a este sitio o a terceros por la información que facilite. Usted se compromete a actuar en forma responsable en este sitio y a tratar a otros visitantes con respeto." 
                                        />
                                        </div>
                                    </p>

                                </div>
                                <div className="bg-gray-50 px-7 pb-6 text-right">
                                    <button
                                        type="button"
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-white  bg-silver text-base Ma-light font-semibold tracking-wide"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        <FaCheck className="text-sm mr-1" />
                                        <FormattedMessage
                                            id="app.buttonModal" 
                                            defaultMessage="Aceptar" 
                                        />
                                    </button>
                                </div>


                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={terminos} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto z-index-Mod" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:align-middle md:w-3/5">
                                <div className=" bg-md px-5 pb-5 pt-7">
                                    <div className="flex justify-between">

                                        <div className="mt-3 col-start-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-xl leading-6 font-bold text-white Ma-demi">
                                                <FormattedMessage
                                                    id="footer.terms" 
                                                    defaultMessage="Términos de uso" 
                                                />
                                            </Dialog.Title>

                                        </div>
                                        <div className="justify-end flex items-center h-12 w-12 rounded-full shadow-xl bg-gray-300 sm:mx-0 sm:h-10 sm:w-10">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center border-gray-30 px-3 py-2 text-base font-extrabold text-black transform motion-safe:hover:scale-110 "
                                                onClick={() => setTerminos(false)}
                                                ref={cancelButtonRef}
                                            >
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-7 py-7 sm:flex sm:flex-row-reverse Ma-light">

                                    <p className="text-base leading-normal tracking-wide txt-silver font-semibold text-justify">
                                        <FormattedMessage
                                            id="term.useDescripOne" 
                                            defaultMessage="Todos los materiales publicados en este sitio pertenecen a MICRODATA S.R.L. u otros Individuos o Instituciones,
                                            quienes han permitido su publicación. Cualquier reproducción, retransmisión, o publicación" 
                                        />
                                        <FormattedMessage
                                            id="term.useDescripTwo" 
                                            defaultMessage=" de todo o parte de los documentos encontrados en este sitio, está expresamente prohibida, a menos que MICRODATA 
                                            S.R.L. o los propietarios del material hayan expresado su consentimiento por escrito, para tales efectos. Cualquier otro derecho también está reservado." 
                                        />
                                    <br/><br/>
                                        <FormattedMessage
                                            id="term.useDescripThree" 
                                            defaultMessage="Aunque el sitio Web de MICRODATA incluye enlaces que proveen acceso directo a otros sitios de Internet, la empresa no asume 
                                            responsabilidad por el contenido o información publicada en estos otros sitios, y no ejerce control editorial o de cualquier otro tipo sobre aquellos sitios." 
                                        />
                                        <FormattedMessage
                                            id="term.useDescripFor" 
                                            defaultMessage=" MICRODATA S.R.L. Proporciona soluciones y servicios de software como un modo de promover y contribuir a mejorar la calidad de los 
                                            procesos y productos de las empresas. La empresa no asume responsabilidad por el uso de la información o la aplicación de las herramientas gratuitas que publica en Internet." 
                                        />

                                    </p>

                                </div>
                                <div className="bg-gray-50 px-7 pb-6 text-right">
                                    <button
                                        type="button"
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-white bg-silver text-base Ma-light font-semibold tracking-wide"
                                        onClick={() => setTerminos(false)}
                                        ref={cancelButtonRef}
                                    >
                                        <FaCheck className="text-sm mr-1" />
                                        <FormattedMessage
                                            id="app.buttonModal" 
                                            defaultMessage="Aceptar" 
                                        />
                                    </button>
                                </div>

                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className="foot-bg text-white text-center bottom-0 w-full Ma-light">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-1">
                    <div className="w-full overflow-hidden m-auto mt-4 place-content-center mx-auto">
                        <div className="px-12">
                            <div className="grid md:place-content-start place-content-center">
                            <img src={logoGris} alt="logo microdata" className="w-80 mb-8"></img>
                            </div>
                            <div className=" grid">
                                <div className="">

                                    <ul className="list-none text-white lg:text-left text-center mb-5 md:mb-0">
                                        <li>
                                            <button onClick={() => setTerminos(true)} className="px-3 hover:text-green-500 text-lg text-gray-300 font-semibold tracking-wide">
                                            <FormattedMessage
                                                id="footer.terms" 
                                                defaultMessage="Términos de uso" 
                                            />
                                            </button>
                                        </li>
                                        <li>
                                            <button onClick={() => setOpen(true)} className="px-3 hover:text-green-500 text-lg text-gray-300 font-semibold tracking-wide">
                                            <FormattedMessage
                                                id="footer.policy" 
                                                defaultMessage="Políticas de Privacidad" 
                                            />
                                            </button>
                                        </li>
                                        {/*
                                            <li>
                                                <a href="/Libro" className="px-3 hover:text-green-500 text-lg text-gray-300 font-semibold tracking-wide">
                                                <FormattedMessage
                                                    id="footer.book" 
                                                    defaultMessage="Libro de reclamaciones" 
                                                />
                                                </a>
                                            </li>
                                        */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-hidden mt-4 md:place-content-center md:mx-auto md:px-0 sm:px-40 px-16">
                        <div className="md:px-4 md:pt-4 px-8 pt-0 pb-0">
                            <ul className="list-none text-white text-left">
                                <li>
                                    <h1 className="text-md pb-2 text-gray-300 font-bold tracking-wide text-lg pl-6 ">
                                    <FormattedMessage
                                        id="app.buttonTwo" 
                                        defaultMessage="CONTACTO" 
                                    />
                                    </h1>
                                    <div className="flex items-start">
                                        <div className="pt-2 justify-start">
                                            <ion-icon name="location-outline" class="log-green md hydrated" role="img" aria-label="map outline"></ion-icon>
                                        </div>
                                        <div className="mt-0 ml-2 text-left">
                                            <div className="mt-2">
                                                <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/vBkUkzLycEKCLxGN9" className="hover:text-green-300">
                                                    <p className="mb-2 font-extralight text-gray-300 font-semibold tracking-wide">
                                                        <FormattedMessage
                                                            id="footer.streetOne" 
                                                            defaultMessage="Calle Sebastián Barranca #311 - 2do piso" 
                                                        />
                                                        <br></br>
                                                        <FormattedMessage
                                                            id="footer.streetTwo" 
                                                            defaultMessage="Urb. La Perla - Cercado, Arequipa" 
                                                        />
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="flex items-start">
                                        <div className="pt-2 justify-start">
                                            <ion-icon name="phone-portrait-outline" class="log-green"></ion-icon>
                                        </div>
                                        <div className="mt-0 ml-2 text-left">
                                            <div className="mt-2">
                                                <p className="mb-2 font-extralight text-gray-300 font-semibold tracking-wide">
                                                    +51-959757304 / 991356970
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="flex items-start">
                                        <div className="pt-2 justify-start">
                                            <ion-icon name="call-outline" class="log-green"></ion-icon>
                                        </div>
                                        <div className="mt-0 ml-2 text-left">
                                            <div className="mt-2">
                                                <p className="mb-2 font-extralight text-gray-300 font-semibold tracking-wide">
                                                    054-202063
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </li>


                            </ul>
                        </div>
                        <div className="md:pb-4 px-4 md:px-0 pt-0 pb-0">
                            <ul className="list-none text-white text-left">
                                <li>

                                    <div className="overflow-hidden">
                                        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-3 md:gap-1">
                                            <div className="place-content-center md:py-4 pt-0 pb-8">
                                                <a className="m-2 p-2 block cursor-pointer w-20 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" target="_blank" rel="noreferrer" href="https://www.facebook.com/microdata.srl.50" >

                                                    <img src={face} alt="facebook transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" className="w-20 m-auto" />
                                                </a>
                                            </div>
                                            <div className=" md:py-4 pt-0 pb-8">
                                                <a className="m-2 p-2 block cursor-pointer w-20 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" target="_blank" rel="noreferrer" href="https://www.instagram.com/microdata.srl/" >
                                                    <img src={insta} alt="facebook" className="w-20 m-auto" />
                                                </a>
                                            </div>
                                            <div className="md:py-4 pt-0 pb-8">
                                                <a className="m-2 p-2 block cursor-pointer w-20 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/microdata-per%C3%BA-43a0651b2/">
                                                    <img src={link} alt="facebook" className="w-20 m-auto" />
                                                </a>
                                            </div>

                                        </div>

                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="overflow-hidden mt-4 md:place-content-center md:mx-auto md:px-0 sm:px-40 px-16">
                        <div className="md:px-4 md:py-4 px-8 pt-0">
                            <ul className="list-none text-white text-left">
                                <h1 className="text-md pb-2 text-gray-300 font-semibold tracking-wide text-lg pl-8">
                                <FormattedMessage
                                    id="footer.information" 
                                    defaultMessage="INFORMACIÓN" 
                                />
                                </h1>
                                <li>

                                    <div className="flex items-start">
                                        <div className="pt-2 justify-start">
                                            <ion-icon name="mail-outline" class="log-green"></ion-icon>
                                        </div>
                                        <div className="mt-0 ml-4 text-left">
                                            <div className="mt-2">
                                                <p className="mb-3 text-gray-300 font-semibold tracking-wide text-md">
                                                <FormattedMessage
                                                    id="footer.forRequestOne" 
                                                    defaultMessage="Para Solicitudes de Propuestas" 
                                                />
                                                <br></br>
                                                <FormattedMessage
                                                    id="footer.forRequestTwo" 
                                                    defaultMessage="/Temas Comerciales:" 
                                                />
                                                <br></br>
                                                    <span className="text-gray-300 font-semibold tracking-wide text-md">consultas@microdata.com.pe</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li className="font-extralight leading-5">
                                    <div className="flex items-start">
                                        <div className="pt-2 justify-start">
                                            <ion-icon name="mail-outline" class="log-green"></ion-icon>
                                        </div>
                                        <div className="mt-0 ml-4 text-left">
                                            <div className="mt-2">
                                                <p className="mb-3 text-gray-300 font-semibold tracking-wide text-md">
                                                <FormattedMessage
                                                    id="footer.forJob" 
                                                    defaultMessage="Para solicitudes de trabajo:" 
                                                />
                                                <br />
                                                    <span className="text-gray-300 font-semibold tracking-wide text-md">talentos@microdata.com.pe</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                                <li className="font-extralight leading-5">

                                    <div className="flex items-start">
                                        <div className="pt-2 justify-start">
                                            <ion-icon name="mail-outline" class="log-green"></ion-icon>
                                        </div>
                                        <div className="mt-0 ml-4 text-left">
                                            <div className="mt-2">
                                                <p className="mb-6 text-gray-300 font-semibold tracking-wide text-md">
                                                <FormattedMessage
                                                    id="footer.forOther" 
                                                    defaultMessage="Para otros asuntos:" 
                                                />
                                                <br />
                                                    <span className="text-gray-300 font-semibold tracking-wide text-md">hola@microdata.com.pe</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>


                </div>
                {/* 
                <div className="px-8 py-2 bg-gray-600 md:text-right text-left">

                    <div className="md:flex md:flex-row-reverse md:space-x-2 md:space-x-reverse md:space-y-0 space-y-2">
                        <div>
                            <button onClick={() => setTerminos(true)} className="px-3 hover:text-green-300">Términos de uso</button>
                        </div>
                        <div>
                            <button onClick={() => setOpen(true)} className="px-3 hover:text-green-300">Políticas de Privacidad</button>|
                            </div>

                        <div>
                            <p className="px-3">Copyright © 2021 MICRODATA. Todos los derechos reservados |</p>
                        </div>
                    </div>

                    <df-messenger chat-title="Virtual assistant - Alejandra" chat-icon="https://i.ibb.co/bXz0J42/Alejandra-BOT.png" intent="WELCOME" agent-id="86871328-8680-4a8b-80f9-a522996357e9" language-code="es"></df-messenger>


                </div>
            */}

            </div>
            <div className="fixed bottom-3 right-3 ">

                <div className="relative">
                    <a
                        target="_blank" rel="noreferrer" href="https://wa.link/iwbfd9"
                        type="button"
                        className="transform cursor-pointer hover:scale-110 motion-reduce:transform-none rounded-full h-16 shadow-lg  w-16 flex items-center justify-center"
                    >
                        {/* <FaWhatsapp className="text-4xl text-white" /> */}
                        <img src={wsp} alt="wsp" className="w-20 m-auto" />
                    </a>


                </div>

            </div>
        </>
    )
}
