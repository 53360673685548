import React from 'react';
import certificadoUno from '../images/Certificados/certificado-1.webp';
import certificadoDos from '../images/Certificados/certificado-2.webp';
import certificadoTres from '../images/Certificados/certificado-3.webp';
import certificadoCuatro from '../images/Certificados/certificado-4.webp';
import {FormattedMessage} from 'react-intl';

class Certificaciones extends React.Component{

    render(){
        return ( 
            <>
                <h1 className="text-center text-xl md:text-4xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book" data-wow-delay="1s"> <span className="font-bold Ma-demi">
                <FormattedMessage
                    id="certifications.titleOne" 
                    defaultMessage="NUESTRAS" 
                />
                </span>
                <FormattedMessage
                    id="certifications.titleTwo" 
                    defaultMessage=" CERTIFICACIONES" 
                />
                </h1>
                <div className="mt-20 grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 lg:gap-8 wow animate__animated  animate__fadeIn" >
                    <div className="m-4 text-center">
                        <img src={certificadoUno} alt="certificadoUno" className="m-auto w-5/12 md:w-6/12 lg:w-7/12 xs-w-50"/>
                    </div>
                    <div className="m-4 xs-mt-5 md:mt-20 lg:mt-4 text-center">
                        <img src={certificadoDos} alt="certificadoDos" className="m-auto w-5/12 md:w-6/12 lg:w-7/12 xs-w-50"/>
                    </div>
                    <div className="m-4 xs-mt-5 md:mt-20 lg:mt-4 text-center">
                        <img src={certificadoTres} alt="certificadoTres" className="m-auto w-full xs-w-50"/>
                    </div>
                    <div className="m-4 xs-mt-5 md:mt-20 lg:mt-4 text-center">
                        <img src={certificadoCuatro} alt="certificadoCuatro" className="m-auto w-full xs-w-50"/>
                    </div>
                </div>
            </>
        )
    }
}

export default Certificaciones;

