import React from 'react';
import imgUnoNosotros from '../images/uno-nosotros.webp';
import {FormattedMessage} from 'react-intl';

class DescripcionNosotros extends React.Component{

    render(){
        return ( 
            <>
                    <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                        <div className="m-auto">
                            <p className="text-gray-500 sm:text-2xl md:text-2xl lg:text-2xl xl:text-xl 2xl:text-1xl text-justify font-medium Ma-book tracking-normal leading-normal txt-silver">
                                <FormattedMessage
                                    id="about.descriptionOne" 
                                    defaultMessage="Desde el 2001, hemos estado" 
                                />
                                <span className='font-bold Ma-med'>
                                <FormattedMessage
                                    id="about.descriptionTwo" 
                                    defaultMessage=" brindando soluciones" 
                                /> 
                                </span> 
                                <FormattedMessage
                                    id="about.descripctionThree" 
                                    defaultMessage=" a empresas y startups de diversos sectores ayudándolos a implementar tecnología y construir sus productos de software, con docenas de clientes atendidos y más de cien proyectos entregados. MICRODATA ha acumulado un poderoso conjunto de experiencia y conocimiento en procesos de ingeniería de software y tecnología en dominios específicos tales como: Salud, Finanzas, Portuario, Venta minorista, Textil, Servicios Educativos, entre otros.  Nuestra sede central se ubica en Perú, sin embargo, exportamos servicios a otros países de la región como Bolivia y Colombia." 
                                />
                            </p>
                        </div>
                        <div className="xs-mt-5 md:pt-20 xl:pt-0">
                            <img className="w-7/12 xl:w-9/12 m-auto object-scale-down" src={imgUnoNosotros} alt="Nosotros" />
                        </div>
                    </div>
            </>
        )
    }
}

export default DescripcionNosotros;

