import React from "react";
import emailjs from 'emailjs-com';
import { Redirect } from 'react-router';
import imgContacto from '../images/contacto.webp';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import mobile from '../images/Iconos/Contacto/graficos-04.png';
import correo from '../images/Iconos/Contacto/graficos-05.png';
import direccion from '../images/Iconos/Contacto/graficos-06.png';
import Editcont from '../images/Editadas/imagenes-03_11zon.webp';
import {FormattedMessage} from 'react-intl';
import '../index.css'

const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const NOMBRES_REGEX = new RegExp(/^[a-zA-Z ]+$/i);


class ContactForm extends React.Component {
    constructor() {
        super();
        this.state = {
            contact: { 
                name: '',
                email: '',
                msn: '',
                interes:" ",
            },
            touched: {
                name: false,
                email: false,
                msn: false,
            },
            errors: {
                required: {
                    name: false,
                    email: false,
                    msn: false,
                },
                valid: {
                    email: true,
                    name: true,
                    msn: true,
                }
            },
            showMessage: false,

        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }
    

    handleSubmit(event) {
        event.preventDefault();
        emailjs.send("default_service","template_rbks8kt", this.state.contact, "user_LUgonnfpJpcK4YNqWxKpv")
		.then((response) => {
				   console.log("SUCCESS!", response.status, response.text);
			
                   this.setState({

                    contact: { ...this.state.contact },
                    showMessage: true
                   });
				
		}, (err) => {
				   console.log("FAILED...", err);
		});
    }

    handleChange(event) {
        const target = event.target;
        const { value, name } = target;
        const errors = {
            required: { ...this.state.errors.required, [name]: false }
        };
        this.setState({
            [name]: value,
            errors: { ...this.state.errors, ...errors },
            contact: { ...this.state.contact, [name]:value}
        });
    }

    handleBlur(event) {
        const field = event.target.name;
        this.setState({
            touched: { ...this.state.touched, [field]: true }
        });
        this.validate(event);
    }

    validate(event) {
        const target = event.target;
        const { value, name } = target;

        if (value.length === 0) {
            const errors = {
                required: { ...this.state.errors.required, [name]: true }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
            return;
        }

        if (name === 'email') {
            this.validateEmail(value);
        }


    }


    validateNombres(name) {
        const nombreIsValid = NOMBRES_REGEX.test(this.state.name);
        const errors = {
            valid: { ...this.state.errors.valid, name: nombreIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateEmail(email) {
        const emailIsValid = EMAIL_REGEX.test(this.state.email);
        const errors = {
            valid: { ...this.state.errors.valid, email: emailIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    hasError(field) {
        return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
    }

    isFormInvalid() {
        const { email, name,msn, errors } = this.state;
        const { required, valid } = errors;
        const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
        const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

        return isSomeFieldInvalid || isSomeFieldRequired;
    }

    displayError(field) {
        const { required, valid } = this.state.errors;
        const errorMessage = `El contenido `;

        if (required[field]) {
            return `${errorMessage} es requerido`;
        }

        if (!valid[field]) {
            return `${errorMessage} no es valido`;
        }
    }

    render() {
        const { email, name,msn, interes, errors } = this.state.contact;

        return (
            <>
                <Navbar titlelight="app.buttonTwo" title="title.empty" titlelightDefaul="Contacto" titleDefaul=" " image={imgContacto} />
                <div className="container xl:px-32 lg:px-18 mx-auto px-10 pt-32 pb-12">


                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={mobile} alt="Números de contacto" className="w-28 m-auto" />

                                </div>
                                <p className="text-center 2xl:text-2xl text-xl txt-silver Ma-light font-semibold tracking-wider">
                                    +51959757304<br></br>+51991356970<br></br>054-202063
                                </p>
                            </div>
                        </div>


                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={correo} alt="Correos de contacto" className="w-28 m-auto" />

                                </div>
                                <p className="text-center 2xl:text-2xl text-xl txt-silver Ma-light font-semibold tracking-wider">
                                    consultas@microdata.com.pe<br></br>hola@microdata.com.pe
                                </p>
                            </div>
                        </div>


                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={direccion} alt="Ubicación de Microdata" className="w-28 m-auto" />

                                </div>
                                <p className="text-center 2xl:text-2xl text-xl txt-silver Ma-light font-semibold tracking-wider">
                                    <FormattedMessage
                                        id="contact.streetOne" 
                                        defaultMessage="Calle Sebastián Barranca" 
                                    />
                                    <br></br>
                                    <FormattedMessage
                                        id="contact.streetTwo" 
                                        defaultMessage="#311 - 2do piso - Urb. La" 
                                    />
                                    <br></br>
                                    <FormattedMessage
                                        id="contact.streetThree" 
                                        defaultMessage="Perla - Cercado - Arequipa" 
                                    /><br></br>
                                    <FormattedMessage
                                        id="contact.streetFor" 
                                        defaultMessage="Perú" 
                                    />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container mx-auto lg:px-18 md:px-0 py-12">
                    <h1 className="text-center 2xl:text-3xl text-2xl txt-md font-semibold  wow animate__animated  animate__fadeIn Ma-light " data-wow-delay="1s"> <span className="font-extrabold Ma-demi">
                        <FormattedMessage
                            id="contact.titleOne" 
                            defaultMessage="MÁS" 
                        />
                        </span>
                        <FormattedMessage
                            id="contact.titleTwo" 
                            defaultMessage=" INFORMACIÓN AQUÍ" 
                        />
                    </h1>

                    <div className="grid lg:grid-cols-2 xl:px-32 md:px-0 md:grid-cols-2 mt-5">
                        <div className="m-auto mt-4 bg-white wow animate__animated animate__fadeIn">
                            <div className="pt-8 pb-6 px-10">
                                <img src={Editcont} alt="Números de contacto" className="2xl:w-9/12 w-11/12 mx-auto" />
                            </div>
                        </div>
                        <div className="mb-8 bg-white wow animate__animated animate__fadeIn">
                            <div className="pt-8 pb-6 Ma-med">
                            { this.state.showMessage ? <Redirect to="/formEnvio" /> : ""}
                                <form onSubmit={this.handleSubmit}>
                                    <div className="">
                                        <div className="px-4 py-5 sm:p-6">
                                            <div className="grid grid-cols-1">
                                                <div className="col-span-1">
                                                    <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-lg ">
                                                    <FormattedMessage
                                                        id="contact.formOne" 
                                                        defaultMessage="Nombre / Empresa:" 
                                                    />
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={name}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur}
                                                        className={this.hasError('name') ? 'error mt-1 block w-full border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-md' : ' mt-1 block w-full border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-md'}
                                                        id="first-name"
                                                        name="name"
                                                        autoComplete="given-name"
                                                    />
                                                    <p className={this.hasError('name') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                                        {this.displayError('name')}
                                                    </p>
                                                </div>
                                                <div className="col-span-6 mt-5">
                                                    <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-lg ">
                                                    <FormattedMessage
                                                        id="contact.formTwo" 
                                                        defaultMessage="Email:" 
                                                    />
                                                        
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={email}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur}
                                                        className={this.hasError('email') ? 'error mt-1 block w-full border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-md' : ' mt-1 block w-full border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-md'}
                                                        name="email"
                                                        id="first-name"
                                                        autoComplete="given-name"
                                                    />
                                                    <p className={this.hasError('email') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                                        {this.displayError('email')}
                                                    </p>
                                                </div>
                                                <div className="col-span-6 mt-5">
                                                    <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-lg ">
                                                    <FormattedMessage
                                                        id="contact.formThree" 
                                                        defaultMessage="Estoy interesado en:" 
                                                    />
                                                       
                                                    </label>

                                                    <select
                                                        id="asunto"
                                                        value={interes} onChange={this.handleChange}
                                                        name="interes"
                                                        autoComplete="asunto-nombre"
                                                        className="mt-1 block w-full p-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800  text-md txt-silver"
                                                    >
                                                        <FormattedMessage id="contact.formSelectOne" defaultMessage="- Elegir -">
                                                            {(id) => <option value={id}>{id}</option>}
                                                        </FormattedMessage>
                                                        <FormattedMessage id="contact.formSelectTwo" defaultMessage="Asuntos comerciales">
                                                            {(id) => <option value={id}>{id}</option>}
                                                        </FormattedMessage>
                                                        <FormattedMessage id="contact.formSelectThree" defaultMessage="Soporte de productos y/o servicios">
                                                            {(id) => <option value={id}>{id}</option>}
                                                        </FormattedMessage>
                                                        <FormattedMessage id="contact.formSelectFor" defaultMessage="Trabaja con nosotros">
                                                            {(id) => <option value={id}>{id}</option>}
                                                        </FormattedMessage>
                                                    </select>
                                                </div>
                                                <div className="col-span-6 mt-5">
                                                    <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-lg ">
                                                    <FormattedMessage
                                                        id="contact.formFor" 
                                                        defaultMessage="Mensaje:" 
                                                    />
                                                        
                                                    </label>
                                                    <textarea
                                                        id="about"
                                                        value={msn}
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleBlur}
                                                        className={this.hasError('msn') ? 'error mt-1 block w-full border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md' : ' mt-1 block w-full border border-gray-300 p-3 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md'}
                                                        
                                                        name="msn"
                                                        rows={3}
                                                       
                                                    />
                                                    <p className={this.hasError('msn') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                                        {this.displayError('msn')}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid place-content-center pt-5">
                                        <button className="mx-auto bg-md text-white py-2 px-10 hover:bg-blue-900 hover:shadow-lg boton-silver text-xl font-medium tracking-wide">
                                            <FormattedMessage
                                                id="contact.formFive" 
                                                defaultMessage="Enviar" 
                                            />
                                        </button>
                                    </div>
                                   
                                </form>

                     

                            </div>
                        </div>

                    </div>
                </div>



                <Footer></Footer>
            </>
        );
    }
}

export default ContactForm;
