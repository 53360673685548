import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { LangProvider } from './context/langContext';

const container = document.getElementById('app');
ReactDOM.render(
    <LangProvider>
        <App />
    </LangProvider>
, container);

