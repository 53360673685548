import React from 'react';
import '../index.css';
import envio from '../images/Iconos/Contacto/iconos-12.png'
import imgContacto from '../images/contacto.webp';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import {FormattedMessage} from 'react-intl';

class FormEnvio extends React.Component {

    render() {
        return (
            <>
                <Navbar titlelight="app.buttonTwo" title="title.empty" image={imgContacto} />

                <div className="mx-auto md:p-28 p-16">
                    <img src={envio} alt="Números de contacto" className="w-36 m-auto mb-10" />
                    <h1 className="text-center text-2xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book" data-wow-delay="1s"> <span className="font-bold Ma-demi">
                        <FormattedMessage
                            id="contact.answerOne" 
                            defaultMessage="¡GRACIAS POR ESCRIBIRNOS!" 
                        />
                        </span><br></br>
                        <FormattedMessage
                            id="contact.answerTwo" 
                            defaultMessage="Nos estaremos comunicando" 
                        />
                        <br></br>
                        <FormattedMessage
                            id="contact.answerThree" 
                            defaultMessage="contigo a la brevedad." 
                        />
                    </h1>
                </div>
                <Footer></Footer>

            </>
        )
    }
}

export default FormEnvio;

