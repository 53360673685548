import React from 'react';
import '../index.css';
import imgProcesos from '../images/proceso-trabajo.webp';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import ProcesoTrabajo from '../components/ProcesoTrabajo';
import hand from '../images/Iconos/iconos-06.png';
import user from '../images/Iconos/iconos-07.png';
import users from '../images/Iconos/iconos-08.png';
import grap from '../images/Iconos/iconos-09.png';
import procImg from '../images/Editadas/imagenes_Mesa-de-trabajo-1.webp';
import {FormattedMessage} from 'react-intl';


class Proceso extends React.Component {

    render() {
        return (
            <>
                <Navbar titlelight="process.titleOne" title="process.titleFor" titlelightDefaul="NUESTRO" titleDefaul="PROCESO DE TRABAJO" image={imgProcesos} />

                <div className="container mx-auto px-14 py-32 xs-py-20 md:py-28 md:px-14 lg:px-44 xs-px-4">


                    <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 ">
                        <div className="m-auto mt-4 bg-white wow animate__animated animate__fadeIn">
                            <div className="lg:pt-6 pb-14 px-10 lg:px-0 Ma-light text-xl leading-relaxed font-medium Ma-book tracking-normal leading-normal txt-silver">
                                <div>
                                    <p className="text-justify">
                                        <FormattedMessage
                                            id="proccess.descriptionOne" 
                                            defaultMessage="Estamos enfocados a" 
                                        />
                                        <span className="Ma-demi">
                                        <FormattedMessage
                                            id="proccess.descriptionTwo" 
                                            defaultMessage=" dar soluciones confiables" 
                                        />
                                        </span>
                                        <FormattedMessage
                                            id="proccess.descriptionThree" 
                                            defaultMessage=" y ofrecer consultorías especializadas a organizaciones/empresas a nivel regional, nacional e internacional." 
                                        />
                                    </p>
                                    <p className="text-justify mt-3">
                                        <FormattedMessage
                                            id="proccess.descriptionFor" 
                                            defaultMessage="Nuestra metodología de gestión de procesos le permitirá obtener en un corto plazo mejoras de eficiencia, efectividad, calidad, reducción de riesgos, reducción de costos y mayores ingresos" 
                                        />
                                        <span className="Ma-demi">
                                        <FormattedMessage
                                            id="proccess.descriptionFive" 
                                            defaultMessage=" logrando así la consolidación de su empresa en el mercado." 
                                        />
                                        </span>
                                    </p>
                                </div>

                                <div className="grid place-content-center pt-16">
                                    <a href="/servicios" className="mx-auto bg-md text-center text-white py-3 px-10 boton-silver hover:shadow-lg text-xl font-medium tracking-wide" >
                                        <FormattedMessage
                                            id="app.buttonOne" 
                                            defaultMessage="NUESTROS SERVICIOS" 
                                        />
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="m-auto mt-4 bg-white wow animate__animated animate__fadeIn">
                            <div className="pt-8 pb-6">
                            <img src={procImg} alt="Números de contacto" className="h-96 m-auto" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="mx-auto bg-gray-200 py-28">
                    <ProcesoTrabajo></ProcesoTrabajo>
                    <div className="grid place-content-center pt-5">
                        <a href="/contacto" className="mx-auto bg-md text-white py-3 px-10 boton-silver text-xl font-medium tracking-wide  Ma-book" >
                        <FormattedMessage
                            id="app.buttonTwo" 
                            defaultMessage="CONTACTO" 
                        />
                        </a>
                    </div>
                </div>

                <div className="container mx-auto lg:px-32 py-24">


                    <div className="grid md:grid-cols-4 sm:grid-cols-2">
                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={hand} alt="planificacion" className="w-24 2xl:w-36 xl:mb-2 m-auto" />
                                    <h1 className="Ma-ultra txt-md text-5xl pt-2 2xl:text-6xl">+120</h1>
                                </div>
                                <p className="text-center text-2xl 2xl:text-3xl txt-silver Ma-light font-semibold tracking-wider">
                                    <FormattedMessage
                                        id="proccess.proyectOne" 
                                        defaultMessage="Proyectos" 
                                    />
                                    <br></br>
                                    <FormattedMessage
                                        id="proccess.proyectTwo" 
                                        defaultMessage=" Entregados" 
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={user} alt="planificacion" className="w-20 2xl:w-32 m-auto" />
                                    <h1 className="Ma-ultra txt-md text-5xl pt-4 2xl:text-6xl">+50</h1>
                                </div>
                                <p className="text-center text-2xl 2xl:text-3xl txt-silver Ma-light font-semibold tracking-wider">
                                    <FormattedMessage
                                        id="proccess.clientOne" 
                                        defaultMessage="Clientes" 
                                    />
                                    <br></br>
                                    <FormattedMessage
                                        id="proccess.clientTwo" 
                                        defaultMessage=" Empresariales" 
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={users} alt="planificacion" className="w-16 2xl:w-24 xl:mb-2 m-auto" />
                                    <h1 className="Ma-ultra txt-md text-5xl pt-2 2xl:text-6xl">+03</h1>
                                </div>
                                <p className="text-center text-2xl 2xl:text-3xl txt-silver Ma-light font-semibold tracking-wider">
                                    <FormattedMessage
                                        id="proccess.partnersOne" 
                                        defaultMessage="Partners" 
                                    />
                                    <br></br>
                                    <FormattedMessage
                                        id="proccess.partnersTwo" 
                                        defaultMessage=" Estrategicos" 
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="max-w-sm rounded overflow-hidden m-auto mt-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 ">
                            <div className="p-6 h-54">
                                <div className="pb-5 text-center">
                                    <img src={grap} alt="planificacion" className="w-20 2xl:w-36  m-auto my-2" />
                                    <h1 className="Ma-ultra txt-md text-5xl pt-4 2xl:text-6xl 2xl:pt-1">+20</h1>
                                </div>
                                <p className="text-center text-2xl 2xl:text-3xl txt-silver Ma-light font-semibold tracking-wider">
                                    <FormattedMessage
                                        id="proccess.yearOne" 
                                        defaultMessage="Años de" 
                                    />
                                    <br></br>
                                    <FormattedMessage
                                        id="proccess.yearTwo" 
                                        defaultMessage=" Experiencia" 
                                    />
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

                <Footer></Footer>
            </>
        )
    }
}


export default Proceso;