import React from 'react';
import SliderClientes from '../components/Sliders/slider1';
import {FormattedMessage} from 'react-intl';

class Clientes extends React.Component{

    render(){
        return ( 
            <>
                <h1 className="text-center text-xl md:text-4xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book" data-wow-delay="1s"> <span className="font-bold Ma-demi">
                <FormattedMessage
                    id="client.titleOne" 
                    defaultMessage="NUESTROS CLIENTES" 
                />
                    </span> 
                <FormattedMessage
                    id="client.titleTwo" 
                    defaultMessage=" Y" 
                />
                    <br></br>
                <FormattedMessage
                    id="client.titleThree" 
                    defaultMessage="ENTIDADES ASOSIADAS" 
                />
                    </h1>
                <div className="px-5 pt-24 wow animate__animated animate__fadeIn" data-wow-delay="0.4s">
                    <SliderClientes></SliderClientes>
                </div>
            </>
        )
    }
}

export default Clientes;