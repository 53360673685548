import React from 'react';
import MenuPrincipal from './MenuPrincipal';
import '../index.css';
import {FormattedMessage} from 'react-intl';

class Navbar extends React.Component{

    render(){
        return ( 
                <div className="fondo-banner" style={{ backgroundImage: `url(${this.props.image})` }}>
                    <MenuPrincipal></MenuPrincipal>
                    <div className="container mx-auto text-white paddings">
                        <h1 className="text-portada uppercase absolute text-xl md:text-4xl txt-white font-medium  wow animate__animated  animate__fadeIn Ma-light top-80 xs-top-16 sm:top-52 md:top-48 lg:top-42 xl:top-60 2xl:top-80">
                            <FormattedMessage
                                id={this.props.titlelight} 
                                defaultMessage={this.props.titlelightDefaul}
                            />
                            &nbsp;<span className='font-bold Ma-demi'>
                            <FormattedMessage
                                id={this.props.title} 
                                defaultMessage={this.props.titleDefaul}
                            />
                            </span></h1>
                    </div>
                </div>
        )
    }
}

export default Navbar;

