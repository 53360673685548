import React from 'react';

import "animate.css";
function Layout(props) {

    return (
        <>
            <div className="bg-white" >
                {props.children}
            </div>


        </>
    )


}

export default Layout;