import React, {Component} from "react";
import Slider from "react-slick";
import alpa from '../../images/Clientes/alpasur-logo.png';
import comsatel from '../../images/Clientes/logo-comsatel.svg';
import repec from '../../images/Clientes/repec-logo.png';
import caja from '../../images/Clientes/logo2.png';
import pacifico from '../../images/Clientes/logo3.png';
import provida from '../../images/Clientes/logo4.png';
import sanna from '../../images/Clientes/logo5.png';
import midis from '../../images/Clientes/logo6.png';
import seal from '../../images/Clientes/logo7.png';
import biot from '../../images/Clientes/logo8.png';
import salud from '../../images/Clientes/logo9.png';
import digemid from '../../images/Clientes/logo10.png';
import ana from '../../images/Clientes/logo11.png';
import cromotex from '../../images/Clientes/logo12.png';
import estilos from '../../images/Clientes/logo13.png';
import fincyt from '../../images/Clientes/logo14.png';

import innovate from '../../images/Clientes/logo15.png';
import cato from '../../images/Clientes/logo16.png';

import incal from '../../images/Clientes/logo17.png';
import concejeros from '../../images/Clientes/logo19.png';
import wallo from '../../images/Clientes/wl-logo.png';
import autodema from '../../images/Clientes/autodema-logo.png';
import tisur from '../../images/Clientes/tisur.png';
import great from '../../images/Clientes/great.png';
import citesoft from '../../images/Clientes/citesoft.png';
import inss from '../../images/Clientes/INS.jpg';
import tramarsa from '../../images/Clientes/tramarsa.png';
import salle from '../../images/Clientes/salle.png';
import salaver from '../../images/Clientes/salaver.png';
import pucp from '../../images/Clientes/pucp.jpg';
import tpc from '../../images/Clientes/TPC.png';


export default class slider1 extends Component {
    render() {
      const settings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
      infinite: false,
      autoplay: true,
      speed: 5000,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
 
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      };
      return (
        <div>
          <Slider {...settings}>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 ">
                        <a href="https://www.tisur.com.pe" target="_blank" rel="noreferrer" >
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={tisur}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://www.sti.com.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={salaver}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://www1.tramarsa.com.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={tramarsa}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://www.comsatel.com.pe/" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={comsatel}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-4">
                        <a href="https://www.tpchimbote.com.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={tpc}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://web.ins.gob.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={inss}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-2">
                        <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={great}></img>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="http://citesoft.unsa.edu.pe:8003" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={citesoft}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://www.ulasalle.edu.pe/ls/" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={salle}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://www.pucp.edu.pe/provincia/arequipa/" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={pucp}></img>
                        </a>
                    </div>
                    <div className="px-3 focus:ring-2 focus:ring-blue-600 pt-5">
                        <a href="https://alpasur.com.bo" target="_blank" rel="noreferrer">
                            <img alt="logo-alpasur" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={alpa}></img>
                        </a>
                    </div>
                    <div className="px-3 pt-5">
                        <a href="https://ensayosclinicos-repec.ins.gob.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-repec" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={repec}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.cajaarequipa.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-CajaArequipa" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={caja}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.pacifico.com.pe/" target="_blank" rel="noreferrer">
                            <img alt="logo-Pacifico" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={pacifico}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="http://www.smprovida.com" target="_blank" rel="noreferrer">
                            <img alt="logo-Pro-Vida" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={provida}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://sanna.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-SANNA" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={sanna}></img>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.gob.pe/midis" target="_blank" rel="noreferrer">
                            <img alt="logo-Midis" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={midis}></img>
                        </a>
                    </div>
                    <div>
                        <a href="http://www.seal.com.pe/Paginas/Inicio.aspx" target="_blank" rel="noreferrer">
                            <img alt="logo-Seal" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={seal}></img>
                        </a>
                    </div>
                    <div>
                        <a href="http://biotcorp.blogspot.com" target="_blank" rel="noreferrer">
                            <img alt="logo-BiotCorp" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={biot}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.gob.pe/minsa" target="_blank" rel="noreferrer">
                            <img alt="logo-Ministerio-de-Salud" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={salud}></img>
                        </a>
                    </div>
                    <div className="pt-5"> 
                        <a href="https://www.digemid.minsa.gob.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-DIGEMID" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={digemid}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.ana.gob.pe/contenido/la-autoridad-nacional-del-agua" target="_blank" rel="noreferrer">
                            <img alt="logo-ANA" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={ana}></img>
                        </a>
                    </div>
                    <div className="pt-5"> 
                        <a href="https://www.cromotex.com.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-CROMOTEX" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={cromotex}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.estilos.com.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-ESTILOS" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={estilos}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.innovateperu.gob.pe/quienes-somos/nuestros-fondos" target="_blank" rel="noreferrer">
                            <img alt="logo-FINCyT" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={fincyt}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.proinnovate.gob.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-INNOVATE-PERU" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={innovate}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.ucsm.edu.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-Universidad Catolica Santa Maria" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={cato}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.incalpaca.com" target="_blank" rel="noreferrer">
                            <img alt="logo-Incalpaca TPX" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={incal}></img>
                        </a>
                    </div>
                    <div className="pt-5">
                        <a href="https://www.consejeros.com.pe/" target="_blank" rel="noreferrer">
                            <img alt="logo-Consejeros" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={concejeros}></img>
                        </a>
                    </div>
                    <div className="px-3 pt-5">
                        <a href="http://www.walloandlily.com" target="_blank" rel="noreferrer">
                            <img alt="logo-WalloLily" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={wallo}></img>
                        </a>
                    </div>
                    <div className="px-3 pt-5">
                        <a href="https://www.autodema.gob.pe" target="_blank" rel="noreferrer">
                            <img alt="logo-autodema" className="w-9/12 xl:w-10/12 m-auto h-1/2" src={autodema}></img>
                        </a>
                    </div>
          </Slider>
        </div>
      );
    }
  }
