import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from './Layout';
import Nosotros from '../pages/Nosotros';
import Servicios from '../pages/Servicios';
import Inicio from '../pages/Inicio';
import Proceso from '../pages/Proceso';
import Contacto from '../pages/Contacto';
import FormEnvio from '../pages/FormEnvio';
import Libro from '../pages/Libro';



function App(){
    

  return (
  
      <BrowserRouter>
          <Layout>
              <Switch>
                  <Route exact path="/" component={Inicio} titulo={''} />
                  <Route exact path="/nosotros" component={Nosotros} titulo={'Nosotros'} />
                  <Route exact path="/servicios" component={Servicios} />
                  <Route exact path="/proceso" component={Proceso} />
                  <Route exact path="/contacto" component={Contacto} />
                  <Route exact path="/formEnvio" component={FormEnvio} />
                  <Route exact path="/Libro" component={Libro} />
              </Switch>
          </Layout>
      </BrowserRouter>
  )
}

export default App;

