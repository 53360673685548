import React, { useState, useEffect } from "react";

import imgContacto from '../images/contacto.webp';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import '../index.css'

const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const NOMBRES_REGEX = new RegExp(/^[a-zA-Z ]+$/i);
const DNI_REGEX = new RegExp(/^[1-9]{1}[0-9]{7}$/i);
const APELLIDOS_REGEX = new RegExp(/^[a-zA-Z]+$/i);
const TELCEL_REGEX = new RegExp(/^[1-9]{1}[0-9]{8}$/i);
const DIRECCION_REGEX = new RegExp(/^[a-zA-Z1-9À-ÖØ-öø-ÿ]+\.?(( |\-)[a-zA-Z1-9À-ÖØ-öø-ÿ]+\.?)* (((#|[nN][oO]\.?) ?)?\d{1,4}(( ?[a-zA-Z0-9\-]+)+)?)$/i);



class ContactForm extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            docnum: '',
            apsmat: '',
            apspat: '',
            tel: '',
            dir: '',
            pro: '',
            detpro: '',
            detRQ: '',
            ped: '',
            pol: '',
            touched: {
                name: false,
                email: false,
                docnum: false,
                apsmat: false,
                apspat: false,
                tel: false,
                dir: false,
                pro: false,
                detpro: false,
                detRQ: false,
                ped: false,
                pol: false,

            },
            errors: {
                required: {
                    name: false,
                    email: false,
                    docnum: false,
                    apsmat: false,
                    apspat: false,
                    tel: false,
                    dir: false,
                    pro: false,
                    detpro: false,
                    detRQ: false,
                    ped: false,
                    pol: false,
                },
                valid: {
                    email: true,
                    name: true,
                    docnum: true,
                    apsmat: true,
                    apspat: true,
                    tel: true,
                    dir: true,
                    pro: true,
                    detpro: true,
                    detRQ: true,
                    ped: true,
                    pol: true,
                }
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    handleChange(event) {
        const target = event.target;
        const { value, name } = target;
        const errors = {
            required: { ...this.state.errors.required, [name]: false }
        };
        this.setState({
            [name]: value,
            errors: { ...this.state.errors, ...errors }
        });
    }

    handleBlur(event) {
        const field = event.target.name;
        this.setState({
            touched: { ...this.state.touched, [field]: true }
        });
        this.validate(event);
    }

    validate(event) {
        const target = event.target;
        const { value, name } = target;

        if (value.length === 0) {
            const errors = {
                required: { ...this.state.errors.required, [name]: true }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
            return;
        }

        if (name === 'email') {
            this.validateEmail(value);
        }
        if (name === 'name') {
            this.validateNombres(value);
        }
        if (name === 'docnum') {
            this.validateDocumento(value);
        }
        if (name === 'apsmat') {
            this.validateApsmat(value);
        }
        if (name === 'apspat') {
            this.validateApspat(value);
        }
        if (name === 'tel') {
            this.validateTel(value);
        }
        if (name === 'dir') {
            this.validateDir(value);
        }

    }
    validateDir(dir) {
        const DirIsValid = DIRECCION_REGEX.test(this.state.dir);
        const errors = {
            valid: { ...this.state.errors.valid, dir: DirIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }


    validateTel(tel) {
        const TelIsValid = TELCEL_REGEX.test(this.state.tel);
        const errors = {
            valid: { ...this.state.errors.valid, tel: TelIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateApspat(apspat) {
        const ApsIsValid = APELLIDOS_REGEX.test(this.state.apspat);
        const errors = {
            valid: { ...this.state.errors.valid, apspat: ApsIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }
    validateApsmat(apsmat) {
        const ApsIsValid = APELLIDOS_REGEX.test(this.state.apsmat);
        const errors = {
            valid: { ...this.state.errors.valid, apsmat: ApsIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateDocumento(docnum) {
        const DocIsValid = DNI_REGEX.test(this.state.docnum);
        const errors = {
            valid: { ...this.state.errors.valid, docnum: DocIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateNombres(name) {
        const nombreIsValid = NOMBRES_REGEX.test(this.state.name);
        const errors = {
            valid: { ...this.state.errors.valid, name: nombreIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateEmail(email) {
        const emailIsValid = EMAIL_REGEX.test(this.state.email);
        const errors = {
            valid: { ...this.state.errors.valid, email: emailIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    hasError(field) {
        return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
    }

    isFormInvalid() {
        const { email, name, docnum, apsmat, apspat, tel, dir, pro, detpro, detRQ, ped,pol, errors } = this.state;
        const { required, valid } = errors;
        const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
        const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

        return isSomeFieldInvalid || isSomeFieldRequired;
    }

    displayError(field) {
        const { required, valid } = this.state.errors;
        const errorMessage = `El contenido `;

        if (required[field]) {
            return `${errorMessage} es requerido`;
        }

        if (!valid[field]) {
            return `${errorMessage} no es valido`;
        }
    }

    render() {
        const { email, name, docnum, apsmat, apspat, tel, dir, pro, detpro, detRQ, ped,pol, errors } = this.state;

        return (
            <>
                <Navbar titlelight="CONTACTO" image={imgContacto} />

                <div className="mx-auto lg:px-40 md:px-0 py-12 bg-gray-200">
                    <h1 className="text-center text-2xl txt-md font-medium  wow animate__animated  animate__fadeIn Ma-book mt-5" data-wow-delay="1s">LIBRO DE <span className="font-bold Ma-demi">RECLAMACIONES</span></h1>
                    <div className="text-center mt-10 mb-10 md:px-36 px-10" >
                        <p className="Ma-light font-semibold tracking-wide txt-silver">Conforme a lo establecido en el Código de Protección y Defensa del Consumidor, esta institución cuenta con un Libro de Reclamaciones a su disposición.</p>
                    </div>
                    <div className="bg-white py-16 shadow-lg shadow sm:rounded-md sm:overflow-hidden">
                        <div className="grid md:grid-cols-2 grid-cols-1 pb-10 border-b-2 border-gray-200 border-dotted md:px-20 px-10">
                            <div class="place-self-start">
                                <h1 className="text-md Ma-light font-semibold mb-2 tracking-wider">DATOS DE LA EMPRESA:</h1>
                                <ul>
                                    <li>
                                        <p className="Ma-light tracking-wider text-sm txt-silver"><span className="font-semibold">Razón Social: </span>MICRODATA S.R.L</p>
                                    </li>
                                    <li>
                                        <p className="Ma-light tracking-wider text-sm txt-silver"><span className="font-semibold">RUC: </span>20498129065</p>
                                    </li>
                                    <li>
                                        <p className="Ma-light tracking-wider text-sm txt-silver"><span className="font-semibold">Dirección: </span>Calle Sebastián Barranca Nº 311 Urb. La Perla - Cercado - Arequipa</p>
                                    </li>
                                </ul>
                            </div>
                            <div class=" md:text-right mt-7 md:mt-0">
                                <h1 className="text-md Ma-light font-semibold mb-2 tracking-wider">HOJA DE RECLAMACIÓN:</h1>
                                <ul>
                                    <li>
                                        <p className=" Ma-light tracking-wide text-sm txt-silver">0001</p>
                                    </li>

                                </ul>
                            </div>

                        </div>


                        <form method="POST" onSubmit={this.handleSubmit}>
                            <h1 className="text-sm Ma-book font-bold mb-2 tracking-wider text-center mt-10 ">IDENTIFICACIÓN DEL CONSUMIDOR Y RECLAMANTE</h1>
                            <div className="pt-4 pb-10 Ma-light border-b-2 border-gray-200 border-dotted ">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-5 gap-5 md:px-20 px-10 ">

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med ">
                                            Tipo de Documento:
                                        </label>
                                        <select
                                            id="asunto"
                                            name="asunto"
                                            autoComplete="asunto-nombre"
                                            className="mt-1 block w-full px-3 py-2 border rounded border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800  text-sm txt-silver"
                                        >
                                            <option>DNI</option>
                                            <option>Carnet de extranjeria</option>

                                        </select>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Número de Documento:
                                        </label>

                                        <input
                                            type="text"
                                            value={docnum}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('docnum') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            id="first-name"
                                            name="docnum"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('docnum') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('docnum')}
                                        </p>
                                    </div>

                                </div>

                                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-5 gap-5 md:px-20 px-10 ">

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Nombres:
                                        </label>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('name') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            id="first-name"
                                            name="name"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('name') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('name')}
                                        </p>
                                    </div>

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Apellidos maternos:
                                        </label>

                                        <input
                                            type="text"
                                            value={apsmat}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('apsmat') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            id="first-name"
                                            name="apsmat"
                                        />
                                        <p className={this.hasError('apsmat') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('apsmat')}
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Apellidos paternos:
                                        </label>

                                        <input
                                            type="text"
                                            value={apspat}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('apspat') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            id="first-name"
                                            name="apspat"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('apspat') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('apspat')}
                                        </p>
                                    </div>

                                </div>

                                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-5 gap-5 md:px-20 px-10 ">

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Correo Electrónico:
                                        </label>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('email') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            name="email"
                                            id="first-name"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('email') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('email')}
                                        </p>
                                    </div>

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Telefono/Celular:
                                        </label>

                                        <input
                                            type="text"
                                            value={tel}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('tel') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            name="tel"
                                            id="first-name"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('tel') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('tel')}
                                        </p>
                                    </div>
                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Dirección:
                                        </label>

                                        <input
                                            type="text"
                                            value={dir}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('dir') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            name="dir"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('dir') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('dir')}
                                        </p>
                                    </div>

                                </div>

                            </div>
                            <h1 className="text-sm Ma-book font-bold mb-2 tracking-wider text-center mt-5 ">IDENTIFICACIÓN DEL PRODUCTO</h1>
                            <div className="pt-2 pb-6 Ma-light">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-5 gap-5 px-20 border-b-2 border-gray-200 border-dotted pb-10">

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Nombre del producto:
                                        </label>
                                        <input
                                            type="text"
                                            value={pro}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('pro') ? 'error mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver' : ' mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver'}
                                            name="pro"
                                            autoComplete="given-name"
                                        />
                                        <p className={this.hasError('pro') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('pro')}
                                        </p>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Detalles:
                                        </label>
                                        <textarea
                                            value={detpro}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('detpro') ? 'error mt-1 block w-full border border-gray-300 px-3 py-2 rounded shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md' : ' mt-1 block w-full border border-gray-300 px-3 py-2 rounded shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md'}
                                            name="detpro"
                                            rows={1}

                                        />
                                        <p className={this.hasError('detpro') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('detpro')}
                                        </p>
                                    </div>

                                </div>

                            </div>

                            <h1 className="text-sm Ma-book font-bold mb-2 tracking-wider text-center mt-5 ">DETALLE SU RECLAMO</h1>
                            <div className="pt-2 pb-6 Ma-light">
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-5 gap-5 md:px-20 px-10">

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Tipo de reclamo:
                                        </label>
                                        <select
                                            id="asunto"
                                            name="asunto"
                                            autoComplete="asunto-nombre"
                                            className="mt-1 block w-full px-3 py-2 border rounded border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800  text-sm txt-silver"
                                        >
                                            <option>Queja</option>
                                            <option>Reclamo</option>

                                        </select>
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Fecha de registro:
                                        </label>
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            value="15/11/2021"
                                            disabled
                                            className="mt-1 block w-full border rounded border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-800 text-sm txt-silver"
                                        />
                                    </div>

                                </div>

                                <div className="grid lg:grid-cols-2 md:grid-cols-2 mt-5 gap-5 md:px-20 px-10">

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Detalle de reclamo/ queja:
                                        </label>
                                        <textarea
                                            id="about"
                                            rows={2}
                                            value={detRQ}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('detRQ') ? 'error mt-1 block w-full border border-gray-300 px-3 py-2 rounded shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md' : ' mt-1 block w-full border border-gray-300 px-3 py-2 rounded shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md'}
                                            name="detRQ"
                                        />
                                        <p className={this.hasError('detRQ') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('detRQ')}
                                        </p>
                                    </div>

                                    <div className="col-span-1">
                                        <label htmlFor="first-name" className="block text-sm font-medium txt-silver text-md Ma-med">
                                            Pedido:
                                        </label>
                                        <textarea
                                            value={ped}
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                            className={this.hasError('ped') ? 'error mt-1 block w-full border border-gray-300 px-3 py-2 rounded shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md' : ' mt-1 block w-full border border-gray-300 px-3 py-2 rounded shadow-sm focus:outline-none focus:ring-blue-200 focus:ring-1 focus:border-blue-900 text-md'}
                                            name="ped"
                                            rows={2}
                                        />
                                        <p className={this.hasError('ped') ? 'error-message__visible text-red-600 font-bold text-xs tracking-wider' : 'error-message text-red-600 font-bold text-xs tracking-wider'}>
                                            {this.displayError('ped')}
                                        </p>
                                    </div>

                                </div>

                                <div className="flex items-center md:px-20 px-10 mt-5">
                                    <input
                                        value={pol}
                                        onChange={this.handleChange}
                                        onBlur={this.handleBlur}
                                        className={this.hasError('pol') ? 'error h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer' : ' h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer'}
                                        name="pol"
                                        type="checkbox"
                                    />
                                    <label htmlFor="remember-me" className="ml-2 block text-sm txt-silver cursor-pointer tracking-wide font-semibold">
                                        Declaro haber leído y acepto la Política de Privacidad. Declaro que los datos consignados son correctos y fiel expresión de la verdad.
                                    </label>
                                </div>
                            </div>

                        </form>

                        <div className="grid place-content-center pt-5">
                            <button

                                onClick={this.handleSubmit}
                                type="submit"
                                className="mx-auto bg-md text-white py-2 px-10 hover:bg-blue-900 hover:shadow-lg" href="/SendForm" >ENVIAR
                            </button>
                        </div>
                    </div>
                </div>



                <Footer></Footer>
            </>
        );
    }
}

export default ContactForm;
